.container {
  padding: 100px 112px;
}

.itemsContainer {
  grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
}

@media only screen and (max-width: 910px) {
  .container {
    padding: 50px 30px;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
  }

  .itemsContainer {
    margin-top: 24px;
    margin-bottom: 24px;
    row-gap: 24px;
  }
}
