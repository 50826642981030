.wrapper {
  padding: 100px 0;
}

.container {
  width: 800px;
}

@media only screen and (max-width: 910px) {
  .wrapper {
    padding: 50px 30px;
  }

  .container {
    width: 100%;
  }
}
