.Container {
  margin: 0;
  color: white;

  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  width: 60%;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0, -50%);
}

.blurBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  filter: blur(8px);
  z-index: -1;
}

@media only screen and (max-width: 910px) {
  .Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    top: unset;
    width: 100%;
    height: auto;
    margin: 48px 0 0 0;
    padding: 32px 40px;
    color: white;
    transform: unset;
    position: flex;
    left: unset;
    text-align: center;
  }
}
