.container {
  padding: 100px 112px;
}

@media only screen and (max-width: 910px) {
  .container {
    padding: 50px 30px;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
  }
}
