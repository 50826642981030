.container {
  padding: 100px 112px 70px 112px;
}

.listWrapper {
  margin-top: 48px;
}

@media only screen and (max-width: 910px) {
  .container {
    padding: 50px 30px 30px 30px;
  }

  .faqList {
    grid-template-columns: auto;
  }

  .listWrapper {
    margin-top: 32px;
  }
}
