.Container {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  width: 100%;
  letter-spacing: -0.02em;
  color: white;
  margin-bottom: 12px;
}

@media only screen and (max-width: 910px) {
  .Container {
    font-size: 36px;
    line-height: 44px;
  }
}
