.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 112px;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 64px 0px 64px;
  gap: 32px;
  width: 100%;
}

.loaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 64px 64px 112px 64px;
  gap: 32px;
  width: 100%;

  color: white;
}

.resultContainer {
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 48px 112px;
  gap: 32px;
  width: 100%;

  color: black;
}

.responsiveContainer {
  display: flex;
  flex-direction: row;
}

.formBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: auto;
  align-items: center;
}

.searchBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 12px;
  max-width: 100%;
}

.searchInput {
  min-width: 400px;
  gap: 17px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;

  border-radius: 6px;

  color: #d4d4d4;

  border: unset;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari/Edge */
    color: #d4d4d4;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #d4d4d4;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #d4d4d4;
  }
}

.searchButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 28px;

  background: #947132;
  border-radius: 6px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin-top: 0px;
  margin-left: 12px;

  color: #ffffff;
  &:hover {
    background: #765a28;
  }
}
.disabledBtn {
  background: rgba(0, 0, 0, 1);
  color: #5f5f5f;
  border: 1px solid #5f5f5f;

  cursor: not-allowed;

  &:hover {
    background: unset;
  }
}

@media only screen and (max-width: 910px) {
  .searchInput {
    min-width: 320px;
  }

  .searchButton {
    margin-top: 12px;
    margin-left: 0px;
  }

  .responsiveContainer {
    flex-direction: column;
  }

  .resultContainer {
    padding: 50px 30px;
  }

  .loaderContainer {
    padding: 64px 0px 112px 0px;
  }
}
