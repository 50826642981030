.wrapper {
  padding: 100px 112px;
}

.listWrapper {
  grid-template-columns: repeat(2, auto);
}

.title {
  font-size: 36px;
}

@media only screen and (max-width: 910px) {
  .wrapper {
    padding: 50px 30px;
  }

  .partPurchaseTitle {
    grid-row-end: 4;
    margin-top: 32px;
  }

  .listWrapper {
    grid-template-columns: auto;
  }

  .title {
    font-size: 32px;
  }
}
